const firebaseConfig = {
  apiKey: 'AIzaSyAZCgqoYO-j0wIJQajsB1aA-PaGfzG8KLo',
  authDomain: 'framable-e1069.firebaseapp.com',
  databaseURL: 'https://framable-e1069.firebaseio.com',
  projectId: 'framable-e1069',
  storageBucket: 'framable-e1069.appspot.com',
  messagingSenderId: '39955342564',
  appId: '1:39955342564:web:e7595583b0404d3749f8f1',
  measurementId: 'G-CTZCVG0KBV'
}
export default firebaseConfig
